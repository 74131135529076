<form class="ui form" #form="ngForm" autocomplete="off" [class.loading]="isLoading">
    <table *ngIf="invoiceLineItems?.length" class="ui table">
        <thead>
            <tr>
                <th>Your plan</th>
                <th></th>
                <th class="right aligned">Pricing</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let item of invoiceLineItems; let index = index">
                <tr *ngIf="item['amount'] > 0">
                    <td>{{ item['description'] }}</td>
                    <td></td>
                    <td class="right aligned">{{ item['amount'] / 100 | currency }}</td>
                </tr>
            </ng-container>
        </tbody>
        <tfoot>
            <tr *ngIf="dueNowSubtotal">
                <td></td>
                <td class="right aligned">Due Now</td>
                <td class="right aligned">{{ dueNowSubtotal | currency }}*</td>
            </tr>
            <tr *ngIf="subscription?.status === 'trialing'">
                <td></td>
                <td class="right aligned">Trial Ends</td>
                <td class="right aligned">{{ subscription?.trialEnd | appDate }}</td>
            </tr>
            <tr *ngIf="monthlySubtotal">
                <td></td>
                <td class="right aligned">Monthly Subtotal</td>
                <td class="right aligned">{{ formatCurrency(monthlySubtotal) | currency }}*</td>
            </tr>

            <tr *ngIf="percentDiscount">
                <td></td>
                <td class="right aligned">Discount</td>
                <td class="right aligned">{{ percentDiscount }}%</td>
            </tr>

            <tr *ngIf="dollarDiscount && !percentDiscount">
                <td></td>
                <td class="right aligned">Monthly Discount</td>
                <td class="right aligned">{{ formatCurrency(dollarDiscount) | currency }}</td>
            </tr>

            <tr *ngIf="monthlySubtotal">
                <td></td>
                @if (percentDiscount) {
                    <td class="right aligned"><strong>Monthly Total After Discount</strong></td>
                    <td class="right aligned">
                        <strong>{{ discountedMonthlyAfterPercentSubtotal | currency }}</strong>
                    </td>
                } @else if (dollarDiscount) {
                    <td class="right aligned"><strong>Monthly Total After Discount</strong></td>
                    <td class="right aligned">
                        <strong>{{ monthlySubtotalAfterDollarDiscount | currency }}</strong>
                    </td>
                } @else {
                    <td class="right aligned"><strong>Monthly Total</strong></td>
                    <td class="right aligned">
                        <strong>{{ formatCurrency(monthlySubtotal) | currency }}</strong>
                    </td>
                }
            </tr>

            <tr>
                <td></td>
                <td class="right aligned"></td>
                <td class="right aligned"><small>*Plus Applicable Taxes</small></td>
            </tr>
        </tfoot>
    </table>
</form>
