import { Component } from '@angular/core';
import { InvoiceService } from '@app/services/invoice.service';
import { InvoiceLineItem } from '@models/settings/billing/invoice.model';
import { Subscription } from '@models/settings/billing/subscription.model';

@Component({
    selector: 'app-form-setup-payment-info',
    templateUrl: './setup-payment-info.form.html',
})
export class SetupPaymentInfoForm {
    invoiceLineItems: InvoiceLineItem[] = [];
    subscription: Subscription | null = null;

    isLoading = false;

    constructor(private invoiceService: InvoiceService) {}

    async ngOnInit(): Promise<void> {
        this.isLoading = true;

        this.subscription = await this.invoiceService.getSubscription();
        this.invoiceLineItems = await this.invoiceService.getInvoiceLineItems();
        this.isLoading = false;
    }

    get monthlySubtotal(): number {
        if (!this.invoiceLineItems.length) {
            return 0;
        }
        return this.invoiceService.calculateMonthlySubtotal(this.invoiceLineItems);
    }

    get dueNowSubtotal(): number {
        if (!this.invoiceLineItems.length) {
            return 0;
        }
        return this.invoiceService.calculateDueNowSubtotal(this.invoiceLineItems);
    }

    get percentDiscount(): number {
        return this.invoiceService.getPercentDiscount(this.subscription);
    }

    get dollarDiscount(): number {
        return this.invoiceService.getDollarDiscount(this.subscription);
    }

    get discountedTotal(): number {
        return this.invoiceService.calculateDiscountedTotal(
            this.monthlySubtotal,
            this.percentDiscount,
            this.dollarDiscount
        );
    }

    get discountedMonthlyAfterPercentSubtotal(): number {
        return this.invoiceService.calculateDiscountedMonthlyAfterPercentSubtotal(
            this.monthlySubtotal,
            this.percentDiscount
        );
    }

    get monthlySubtotalAfterDollarDiscount(): number {
        return this.invoiceService.calculateMonthlySubtotalAfterDollarDiscount(
            this.monthlySubtotal,
            this.dollarDiscount
        );
    }

    formatCurrency(value: number): number {
        return this.invoiceService.convertToCurrency(value);
    }
}
