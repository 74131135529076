import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgForm } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { fromEvent } from 'rxjs';

@Component({
    selector: 'app-form-setup-admin-welcome',
    templateUrl: './setup-admin-welcome.form.html',
    styleUrls: ['./setup-admin-welcome.style.scss'],
    animations: [
        trigger('fadeEffect', [
            state('no-fade', style({ opacity: 0 })),
            state('fade', style({ opacity: 1 })),
            transition('no-fade => fade', [animate('300ms ease-in-out')]),
            transition('fade => no-fade', [animate('300ms ease-in-out')]),
        ]),
    ],
})
export class SetupAdminWelcomeForm implements AfterViewInit, OnInit {
    @ViewChild('scrollableContent', { static: false }) scrollableContent: ElementRef;
    @ViewChild('form', { static: true }) form: NgForm;
    @Input() formData: any = {};
    @Output() valid: EventEmitter<any> = new EventEmitter<any>();

    fadeState = 'fade';
    private destroyRef = inject(DestroyRef);

    constructor(public auth: AuthService) {}

    ngOnInit(): void {
        this.form.statusChanges?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.valid.emit({ valid: this.form.valid, data: this.formData });
        });
    }

    ngAfterViewInit(): void {
        fromEvent(this.scrollableContent.nativeElement, 'scroll')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.getFadeState());
    }

    getFadeState(): void {
        const element = this.scrollableContent.nativeElement;
        const isAtBottom = element.scrollHeight - element.scrollTop <= element.clientHeight + 1;

        this.fadeState = isAtBottom ? 'no-fade' : 'fade';
    }
}
