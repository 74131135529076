import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { NotifyService } from '@app/services/notify.service';
import { StepsComponent } from '@app/components';
import { Company } from '@models/company/company.model';
import { Office } from '@models/company/office.model';
import { Employee } from '@models/employee/employee.model';
import { Card } from '@models/settings/billing/card.model';
import { FeatureService } from '@app/services/feature.service';
import { FeatureFlag } from '@app/enums';
import { CompanyMetaService } from '@app/modules/company/services/company-meta.service';

@Component({
    templateUrl: 'setup.view.html',
    styleUrls: ['setup.style.scss'],
})
export class SetupView implements OnInit, OnDestroy {
    @ViewChild('stepHeader', { static: true }) stepHeader: StepsComponent;
    isLoading = true;
    step = 0;
    currentStepValid = false;
    stepData: any[] = [{}, {}, {}, {}, {}];
    stepIndexes: { [key: string]: number } = {};
    errorMessage: string | null;
    hasAeLedSignUp = false;
    private _formSteps: any[] = [
        { step: 'Company' },
        { step: 'Administrator' },
        { step: 'Payment' },
        { step: 'Get Started' },
    ];

    constructor(
        public auth: AuthService,
        private companyMetaService: CompanyMetaService,
        private readonly featureService: FeatureService,
        private notify: NotifyService
    ) {}

    async ngOnInit(): Promise<void> {
        $('body').addClass('login');
        $(window).resize(() => {
            this.renderForm();
        });

        await this.setFeatureFlag();

        // If AeLedSign up feature flag is enabled, add 'Plan Review' step
        if (this.hasAeLedSignUp) {
            this._formSteps.unshift({ step: 'Plan Review' });
        }

        // Cache step indexes after configuring form steps
        this.cacheStepIndexes();

        try {
            // Fetch company data
            const company = await Company.with('reps').find(this.auth.user.companyId);
            this.stepData[this.stepIndexes['Company']] = company;
            this.stepData[this.stepIndexes['Get Started']] = company;

            // Fetch employee data
            const employee = await Employee.param('company', this.auth.user.companyId).find(this.auth.user.id);
            employee.firstName = this.auth.account.legalFirstName;
            employee.lastName = this.auth.account.legalLastName;

            this.stepData[this.stepIndexes['Administrator']] = employee;

            this.renderForm();
        } catch (error) {
            console.error('Error loading data:', error);
        }
    }

    ngOnDestroy(): void {
        $('body').removeClass('login');
    }

    onNextStep(): void {
        if (this.step === this.stepIndexes['Payment']) {
            this.done();
        }

        if (this.stepData[this.stepIndexes['Company']].fullAddress) {
            this.stepData[this.stepIndexes['Payment']].province =
                this.stepData[this.stepIndexes['Company']].fullAddress.administrative_area_level_1;
        }

        this.step = this.step + 1;
        this.stepHeader.activeStep = this.step;
    }

    onBackStep(): void {
        this.step = this.step - 1;
        this.stepHeader.activeStep = this.step;
    }

    formSteps(): any[] {
        return this._formSteps;
    }

    stepValidated(valid: boolean, data: any, step: number): void {
        this.stepData[step] = data;
        this.currentStepValid = valid;
    }

    done(): void {
        this.isLoading = true;
        this.errorMessage = null;

        this.updateCompany()
            .then((company) => this.createCompanyAddress(company))
            .then((company) => this.updateAdmin(company))
            .then((company) => {
                if (this.hasAeLedSignUp) {
                    this.updateTosEmail();
                }
                return company;
            })
            .then((company) => this.createTrial(company))
            .then(() => (this.isLoading = false))
            .catch(({ err, step }) => {
                if (step) {
                    this.step = step;
                }
                this.errorMessage = err.message;
                this.notify.error('Error');

                this.isLoading = false;
            });
    }

    goToDashboard(): void {
        window.location.href = '/dashboard';
    }

    /**
     * Adjust the login form to center window
     * Ensure body has classes related to login
     */
    private renderForm(): void {
        setTimeout(() => {
            $('body').addClass('login');
            $('.ui.checkbox').checkbox();
            $('[name="email"]').focus();
            this.isLoading = false;
        });
    }

    private cacheStepIndexes(): void {
        this.stepIndexes = this._formSteps.reduce((acc, step, index) => {
            acc[step.step] = index;
            return acc;
        }, {});
    }

    private updateCompany(): Promise<Company> {
        return new Promise<Company>((resolve, reject) => {
            const companyData = this.stepData[this.stepIndexes['Company']];
            companyData.doingBusinessAsName = companyData.name;

            if (!companyData.prTenantId) {
                delete companyData.prTenantId;
            }
            if (!companyData.prCompanyId) {
                delete companyData.prCompanyId;
            }

            companyData
                .save()
                .then((company) => {
                    resolve(company);
                })
                .catch(() => {
                    reject('Could not update company');
                });
        });
    }

    private createCompanyAddress(company: Company): Promise<Company> {
        return new Promise<Company>((resolve, reject) => {
            const office = new Office({
                name: this.stepData[this.stepIndexes['Company']]['address'],
                addressLine1: this.stepData[this.stepIndexes['Company']]['address'],
                city: this.stepData[this.stepIndexes['Company']]['city'],
                province: this.stepData[this.stepIndexes['Company']]['province'],
                country: this.stepData[this.stepIndexes['Company']]['country'],
                postalCode: this.stepData[this.stepIndexes['Company']]['postalCode'],
            });

            office
                .param('company', this.auth.user.companyId)
                .save()
                .then(() => {
                    resolve(company);
                })
                .catch(() => {
                    reject('Could not create company office');
                });
        });
    }

    private updateAdmin(company: Company): Promise<Company> {
        return new Promise<Company>((resolve, reject) => {
            const employeeData = this.stepData[this.stepIndexes['Administrator']];
            employeeData.status = 'active';
            employeeData.employmentType = 'full-time';
            employeeData
                .param('company', this.auth.user.companyId)
                .save()
                .then(() => resolve(company))
                .catch(() => reject('Could not update employee'));
        });
    }

    private createTrial(company: Company): Promise<Company> {
        return new Promise<Company>((resolve, reject) => {
            const trialData = this.stepData[this.stepIndexes['Company']];
            const card = new Card({
                token: trialData.token,
            });

            card.save()
                .then((_) => {
                    resolve(company);
                })
                .catch((err) => reject({ err, step: 2 }));
        });
    }

    private async setFeatureFlag(): Promise<void> {
        this.hasAeLedSignUp = await this.featureService.has(FeatureFlag.aeLedSignUp);
    }

    private async updateTosEmail(): Promise<void> {
        try {
            await this.companyMetaService.setTosComplete(this.auth.account.email);
        } catch (error) {
            console.error('Error accepting Terms of Service:', error);
        }
    }
}
