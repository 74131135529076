import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { InvoiceService } from '@app/services/invoice.service';

import { InvoiceLineItem } from '@models/settings/billing/invoice.model';
import { Subscription } from '@models/settings/billing/subscription.model';

declare let window: any;

@Component({
    selector: 'app-form-setup-payment',
    templateUrl: './setup-payment.form.html',
    styleUrls: ['./setup-payment.style.scss'],
})
export class SetupPaymentForm {
    @ViewChild('form', { static: true }) form: NgForm;

    invoiceLineItems: InvoiceLineItem[] = [];
    subscription: Subscription | null = null;

    isLoading = false;

    @Input() formData: any = {};

    @Output() valid: EventEmitter<any> = new EventEmitter<any>();

    formValid = false;

    stripe: any;
    stripe_elements: any;
    card_element: any;
    card: any = {};

    constructor(private invoiceService: InvoiceService) {
        window.skipPayment = () => {
            this.formData['token'] = 'tok_visa';
            this.formValid = true;
            this.valid.emit({ valid: this.formValid, data: this.formData });
        };
    }

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.setupStripe();
        this.valid.emit({ valid: this.formValid, data: this.formData });

        this.subscription = await this.invoiceService.getSubscription();
        this.invoiceLineItems = await this.invoiceService.getInvoiceLineItems();
        this.isLoading = false;
    }

    get monthlySubtotal(): number {
        if (!this.invoiceLineItems.length) {
            return 0;
        }
        return this.invoiceService.calculateMonthlySubtotal(this.invoiceLineItems);
    }

    get dueNowSubtotal(): number {
        if (!this.invoiceLineItems.length) {
            return 0;
        }
        return this.invoiceService.calculateDueNowSubtotal(this.invoiceLineItems);
    }

    get percentDiscount(): number {
        return this.invoiceService.getPercentDiscount(this.subscription);
    }

    get dollarDiscount(): number {
        return this.invoiceService.getDollarDiscount(this.subscription);
    }

    get discountedTotal(): number {
        return this.invoiceService.calculateDiscountedTotal(
            this.monthlySubtotal,
            this.percentDiscount,
            this.dollarDiscount
        );
    }

    get discountedMonthlyAfterPercentSubtotal(): number {
        return this.invoiceService.calculateDiscountedMonthlyAfterPercentSubtotal(
            this.monthlySubtotal,
            this.percentDiscount
        );
    }

    get monthlySubtotalAfterDollarDiscount(): number {
        return this.invoiceService.calculateMonthlySubtotalAfterDollarDiscount(
            this.monthlySubtotal,
            this.dollarDiscount
        );
    }

    get formattedDollarDiscount(): number {
        return this.invoiceService.convertToCurrency(this.dollarDiscount);
    }

    get formattedMonthlySubtotal(): number {
        return this.invoiceService.convertToCurrency(this.monthlySubtotal);
    }

    formatCurrency(value: number): number {
        return this.invoiceService.convertToCurrency(value);
    }

    createToken(): void {
        this.isLoading = true;
        this.invoiceService.createToken(this.invoiceService.card_element, this.formData).then((result) => {
            if (result.error) {
                // Inform the user if there was an error
                const errorElement = document.getElementById('card-errors');
                errorElement.textContent = result.error.message;
                this.isLoading = false;
            } else {
                // Send the token to your server
                this.isLoading = false;
                this.stripeTokenHandler(result.token);
            }
        });
    }

    private setupStripe(): void {
        setTimeout(() => {
            const cardElement = this.invoiceService.setupStripe();
            cardElement.mount('#card-element');
            cardElement.addEventListener('change', function (event) {
                const displayError = document.getElementById('card-errors');
                displayError.textContent = event.error ? event.error.message : '';
            });
        });
    }

    private stripeTokenHandler(token): void {
        this.formData['token'] = token.id;
        this.formValid = true;
        this.valid.emit({ valid: this.formValid, data: this.formData });
    }
}
